import $$ from 'cmn-utils';
import modelEnhance from '@/utils/modelEnhance';
import { routerRedux } from 'dva/router';

export default modelEnhance({
  namespace: 'global',

  state: {
    menu: [],
    flatMenu: [],
  },

  effects: {
    *getMenu({ payload }, { call, put }) {
      
      const { ret, data } = yield call(getMenu, payload);
      
      if (ret === 200) {

        const menuData =[{
          name: '首页',
          icon: 'DashboardOutlined',
          path: '/dashboard',
        }]

        const newData = data.adm.children;
        for(let i in newData){
          let pitem = {}
          pitem.name = newData[i].title;
          pitem.path = newData[i].path;
          if(newData[i].icon) pitem.icon = newData[i].icon;
          if (newData[i].children) {
            pitem.children = [];
            for(let j in newData[i].children){
              let pjitem = {};
              pjitem.name = newData[i].children[j].title;
              pjitem.path = newData[i].children[j].path;
              if(newData[i].children[j].icon) pjitem.icon = newData[i].children[j].icon
              pitem.children.push(pjitem);
            }
          }
          menuData.push(pitem);
          //console.log('process:',menuData);
        }
        menuData.push({
          name: '修改个人信息',
          icon: 'UserOutlined',
          path: '/user/info',
        })
        //console.log('all:',menuData);
        

        const loopMenu = (menu, pitem = {}) => {
          menu.forEach(item => {
            if (pitem.path) {
              item.parentPath = pitem.parentPath ? pitem.parentPath.concat(pitem.path) : [pitem.path];
            }
            if (item.children && item.children.length) {
              loopMenu(item.children, item);
            }
          });
        }
        loopMenu(menuData);
        yield put({
          type: 'getMenuSuccess',
          payload: menuData,
        });
      }
    else if(ret === 402){
      yield put(routerRedux.push('/sign/login'))
    }
    },
  },

  reducers: {
    getMenuSuccess(state, { payload }) {
      return {
        ...state,
        menu: payload,
        flatMenu: getFlatMenu(payload),
      };
    }
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach(item => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

export async function getMenu(payload) {
  return $$.post('/auth/role/getAccess', payload);
}